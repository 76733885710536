const routes = [
    {
        path: "/",
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/pages/index')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/about')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login')
    },
]


export default routes